.toy {
    position: relative;
    margin: 5vmin auto;
    width: 90vmin;
}

.ball {
    position: relative;
    border-radius: 50%;
    width: 90vmin;
    height: 90vmin;
    overflow: hidden;
    background: radial-gradient(circle at 30vmin 30vmin, #444, #000);
}

/* Answers */
.answers {
    width: 90vmin;
    height: 90vmin;
}

.answer {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    left: 36vmin;
    top: 34.6vmin;
    width: 18vmin;
    height: 20.8vmin;
    color: white;
    text-align: center;
    /*font-family: sans-serif;*/
    font-variant: small-caps;
    font-size: 2.1vmin;
    line-height: 2.4vmin;
    opacity: 0;
    transition: opacity 1s;
    animation: floating 6s linear infinite;
}

/* Triangle */
.answer::before {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    border-left: 8.8vmin solid transparent;
    border-right: 8.8vmin solid transparent;
    border-radius: 0.4vmin;
    width: 0.4vmin;
    height: 0;
}

.answer.up::before {
    top: 0.4vmin;
    border-bottom: 15.2vmin solid #1c23e8;
}

.answer.down::before {
    bottom: 0.4vmin;
    border-top: 15.2vmin solid #1c23e8;
}

/* Overlay */
.answer::after {
    content: "";
    z-index: 1;
    position: absolute;
    left: -7vmin;
    top: -5vmin;
    border-radius: 50%;
    width: 32vmin;
    height: 32vmin;
    background: linear-gradient(to left, rgba(0,0,0,0.9), rgba(0,0,0,0));
    animation: rotating 6s infinite linear;
}

/* Input control */
input {
    display: none;
}

input:checked + .answer {
    opacity: 1;
    transition: opacity 2s 1s;
}

form:active input:checked + .answer {
    opacity: 0;
    transition: opacity 1s;
}

/* Invisible labels */
.labels {
    position: absolute;
    top: 0;
    animation: random 1s steps(20) infinite;
}

form:active .labels {
    animation-play-state: paused;
}

label {
    display: block;
    width: 90vmin;
    height: 90vmin;
    cursor: pointer;
}

/* Window to see the answer */
.window {
    position: absolute;
    left: 22.5vmin;
    top: 22.5vmin;
    width: 45vmin;
    height: 45vmin;
    border-radius: 50%;
    background: radial-gradient(#002, #001 18vmin, #666 18vmin, #000 18.5vmin, #000 22vmin, #666 22vmin, #000 22.5vmin);
}

/* Number 8 */
.eight {
    position: absolute;
    left: 22.5vmin;
    top: 22.5vmin;
    width: 45vmin;
    height: 45vmin;
    border-radius: 50%;
    background: #fff;
    font-size: 27vmin;
    font-family: sans-serif;
    text-align: center;
    line-height: 45vmin;
    transition: opacity 1s;
}

form:valid .eight {
    opacity: 0;
}

/* "Uniform distribution" of labels */
@keyframes random {
    to {
        transform: translateY(-1710vmin);
    }
}

/* Triangle gently floating around */
@keyframes floating {
    from {
        transform: rotateZ(0) rotateY(15deg) translateZ(6.8vmin) rotateZ(0);
    }
    to {
        transform: rotateZ(1turn) rotateY(15deg) translateZ(6.8vmin) rotateZ(-1turn);
    }
}

/* Roatating overlay */
@keyframes rotating {
    to {
        transform: rotate(1turn);
    }
}

/* Instructions */
.instruction {
    z-index: -1;
    position: absolute;
    top: -10vmin;
    left: -10vmin;
    width: 110vmin;
    height: 110vmin;
    text-align: center;
    /*font-family: fantasy;*/
    font-size: 3vmin;
    line-height: 4.2vmin;
    opacity: 0;
}

.instruction:nth-of-type(1) {
    transform: rotate(-45deg);
    animation: instruction 1s both;
}

.instruction:nth-of-type(2) {
    transform: rotate(45deg);
    animation: instruction 1s 1s both;
}

.instruction:nth-of-type(3) {
    transform: rotate(-45deg) translateY(102vmin);
    animation: instruction 1s 2s both;
}

.instruction:nth-of-type(4) {
    transform: rotate(45deg) translateY(102vmin);
    animation: instruction 1s 3s both;
}

form:valid ~ .instruction {
    display: none;
}

@keyframes instruction {
    to {
        opacity: 1;
    }
}
